import { atom } from "jotai";
import { ReactNode } from "react";

export type StatusGlobalProps = Record<string, boolean>;

export const statusGlobalOpts: Record<string, string> = {
  employeeTemp: "employe-temp",
};

const statusGlobalAtom = atom<StatusGlobalProps>(
  Object.values(statusGlobalOpts).reduce(
    (prev, key) => ({ ...prev, [key]: false }),
    {},
  ),
);

export default statusGlobalAtom;
